import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import useStyles from './styles'
import { isArray, isEmpty } from 'lodash'
import { checkNull } from '../../Helpers'

const PricingBreakdown = (props) => {
  const {
    finalAmount,
    paymentData,
    chargeData,
    primaryColor,
    linearColorsName,
    isGradientButton,
    changeBtnText,
    onClick,
    currency_symbol,
  } = props

  const styles = useStyles()
  return (
    <Grid
      item
      xs={12}
      direction={'column'}
      style={{
        height: '90vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={2}
        className={styles.cardWidth}
        style={{
          borderRadius: 4,
          borderWidth: 1,
          height: 'auto',
          // width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ padding: 15, borderBottom: '1px solid #c6c6c6' }}>
          <Typography
            textAlign={'center'}
            style={{
              fontSize: 16,
              fontWeight: 'bold',
            }}
          >
            Payment Summary
          </Typography>
        </div>

        <div className={styles.pricingBreakdown}>
          <Grid
            container
            justifyContent='space-between'
            style={{ padding: '10px 10px' }}
          >
            <Typography style={{ fontSize: 14 }}>Amount</Typography>
            <Typography style={{ fontSize: 14 }}>
              {checkNull(chargeData?.withoutChargeAmount?.toString())
                ? 0
                : `${
                    currency_symbol[paymentData?.country_code]
                  }${chargeData?.withoutChargeAmount?.toFixed(2)}`}
            </Typography>
          </Grid>

          {isArray(chargeData?.mainChargeData) &&
            !isEmpty(chargeData?.mainChargeData) &&
            chargeData?.mainChargeData?.map((item, index) => {
              const amountToAdd = paymentData?.chargeAsFixedAmount
                ? item?.value
                : (item?.value * paymentData?.amount) / 100
              return (
                <>
                  {item?.slug !== 'payment_gateway_fee' && (
                    <Grid
                      container
                      justifyContent='space-between'
                      style={{ padding: '0px 10px' }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        {item?.name}
                      </Typography>
                      <Typography style={{ fontSize: 14 }}>
                        {currency_symbol[paymentData?.country_code]}
                        {
                          // item?.slug === 'payment_gateway_fee'
                          // ? Number(paymentGatwayFee)?.toFixed(2)
                          //   :
                          amountToAdd?.toFixed(2)
                        }
                      </Typography>
                    </Grid>
                  )}
                </>
              )
            })}
          <Grid
            container
            justifyContent='space-between'
            className={styles.pricingBreakdown_final}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              Payable amount
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {checkNull(finalAmount?.toString())
                ? 0
                : `${currency_symbol[paymentData?.country_code]}${Number(
                    finalAmount
                  )?.toFixed(2)}`}
            </Typography>
          </Grid>
        </div>
      </Paper>

      <Grid
        display='flex'
        justifyContent='center'
        alignItems='center'
        mb={3}
        style={{ marginTop: 10 }}
      >
        <Button
          variant={'contained'}
          sx={{
            '&.MuiButton-containedPrimary': {
              background: isGradientButton
                ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                : primaryColor,
              ':hover': {
                background: isGradientButton
                  ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                  : primaryColor,
              },
            },
            minWidth: '140px !important',
            fontWeight: 'bold !important',
          }}
          fullWidth
          onClick={() => {
            // if (handleModal) {
            //   handleModal(true)
            // }
            onClick()
          }}
        >
          {false ? (
            <CircularProgress size={28} style={{ color: '#fff' }} />
          ) : changeBtnText ? (
            `${changeBtnText} ${
              currency_symbol[paymentData?.country_code]
            }${Number(finalAmount)?.toFixed(2)}`
          ) : (
            'Yes'
          )}
        </Button>
      </Grid>
      <Grid display='flex' justifyContent='center' alignItems='center'>
        <Typography fontSize={14} color={'#9D9D9D'}>
          + Including Applicable Charges
        </Typography>
      </Grid>
      <Grid
        display='flex'
        justifyContent='center'
        alignItems='center'
        mb={3}
        style={{ marginTop: 10 }}
      >
        <Typography fontSize={12}>Powered by&nbsp;</Typography>
        <Typography
          fontWeight={'bold'}
          fontSize={14}
          className={styles.gradientText}
        >
          AautiPay
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PricingBreakdown
