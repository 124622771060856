import axios from 'axios'

/**
 * Makes an asynchronous API request with progress handling for payment-related operations.
 *
 * @function
 * @async
 * @param {string} apiurl - The URL of the API endpoint.
 * @param {string} method - The HTTP method for the request (e.g., "GET" or "POST").
 * @param {Object} data - The data to be sent in the request body.
 * @param {string} token - The authorization token for the request.
 * @returns {Promise<Object>} A promise that resolves to the API response data.
 * @throws {Error} If the API request encounters an error.
 */
const url = 'https://aautidev.aautipay.com/plugin/' //dev,staging
// const url = 'https://aautipayprodapi.aautipay.com/plugin/' //demo,prod

export const liveUrl = url

export const getApiDataProgressPayment = async (
  apiurl,
  method,
  data,
  token
) => {
  let authHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  try {
    let response = await axios({
      method: method,
      url: apiurl,
      timeout: 30000,
      headers: authHeaders,
      data: data || {},
    })
    if (response?.data?.code == 'authentication_failed') {
      return
    }
    let responseStatus = response.status
    const res = response?.data || {
      status: responseStatus === 200 ? true : responseStatus,
      response: response.data,
    }
    return res
  } catch (error) {
    if (error.response) {
      let returnObj
      if (error.response.status === 400) {
        returnObj = error?.response?.data
      }
      if (error.response.status === 404) {
        returnObj = error?.response?.data
      }
      if (error.response.status === 403) {
        if (!error.response.data.code) {
          return
        }
      }
      if (
        error?.response?.data?.message ===
        'Your request was made with invalid credentials.'
      ) {
        return
      }
      return returnObj
    }
    console.log('error----APIHelper--->', error)
  }
}
