import { useEffect, useState } from 'react'
import PaymentAggregator from '../PaymentAggregator'
import { getApiDataProgressPayment, liveUrl } from '../APIHelper'
import PaymentStatus from '../PaymentStatus'
import { CircularProgress, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { checkNull } from '../../Helpers'

const HomePage = () => {
  const primaryColor = '#0068EF'
  let params = new URL(document.location.toString()).searchParams
  let id = params.get('id')
  const [data, setData] = useState({
    payObj: {},
    status: false,
    failMessage: '',
    initialStatus: false,
  })
  const staticData = {
    PaymentType: '', //require => one_time, subscription, custom_subscription
    paymentData: {
      name: 'Ronak',
      amount: 50,
      email: '',
      country_code: 'IN',
      app_token:
        'app_5B7768DA83B0AE019F437C0CCD61C5EE52A9ED60F3A566EBE1F286009B61',
      currency: 'INR',
      transaction_code: 'txn_1234567',
      mobile: '',
      payThroughEMI: false,
      payThroughRecurrence: false,
      updateSubscription: false, //for update subscription like cancel,pause,resume,update payment Method,
      //for razorpay there is no update payment method
      updateSubscriptionType: 'paymentMethod', //cancel,pause,resume,paymentMethod (required if updateSubscription=true)
      recurrenceObj: {
        intervalType: 'monthly', //weekly,monthly,yearly
        //default is 1
        interval_count: 1, //if interval_count=2 then it is for every 2 (months,week,year)
        //default is 0 means forever
        iterations: 0, //if suppose iteration is 2 then it will charge 2 (months,week,year)
        start_at: '', //in local date time format for future subscription schedule
        order_code: '08D2665B0B8B0BB104845056E7B12911F29573259DE8156338', //required for update payment method
      },
    }, //require
    onPaymentDone: () => {
      // setTimeout(() => setPaySuccess(false), 3000)
    },
    onCloseClick: () => {},
    appCharges: [
      {
        id: 1,
        name: 'Platform Fees',
        slug: 'platform_fees',
        type: 'exclude' === 'exclude' ? 'exclusive' : 'inclusive',
        value: 2,
      },
      // {
      //   id: 2,
      //   name: 'Payment Gateway Fee',
      //   slug: 'payment_gateway_fee',
      //   type: 'exclude' === 'exclude' ? 'exclusive' : 'inclusive',
      //   value: 0,
      // },
    ],
    noCharge: false,
    pluginURL: 'staging', //staging, dev, prodapi
    changeBtnText: 'Pay',
    showHeader: true,
    primaryColor: '#0068EF',

    // For gradient
    isGradientButton: true,
    linearColorsName: ['#0BBCED', '#1252A5'],
    themeColor: '#F5F9FF',
  }
  async function callApi() {
    setData((prev) => ({ ...prev, status: true }))
    //  setPageLoader(true)
    try {
      // Step 3: Make an asynchronous API request to the login endpoint
      const response = await getApiDataProgressPayment(
        `${liveUrl}getIframe/${id}`, // URL for the login endpoint
        'GET'
      )
      if (response?.status == false) {
        //  setPageLoader(false)
        setData((prev) => ({ ...prev, status: false }))
      } else {
        const res = response?.data
        const data = {
          ...staticData,
          ...res,
          paymentData: { ...staticData?.paymentData, ...res?.paymentData },
        }
        setData((prev) => ({
          ...prev,
          payObj: data,
          status: false,
        }))
      }
    } catch (error) {
      setData((prev) => ({
        ...prev,
        initialStatus: true,
        failMessage: error?.message,
      }))
      console.log('error:', error)
    }
  }
  useEffect(() => {
    setData((prev) => ({ ...prev, status: true }))
    let timeout
    timeout = setTimeout(() => {
      setData((prev) => ({ ...prev, initialStatus: false }))
      if (!isEmpty(params.get('id'))) {
        callApi()
      } else {
        setData((prev) => ({ ...prev, initialStatus: true, status: false }))
      }
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      {data?.status ? (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CircularProgress size={30} style={{ color: primaryColor }} />
          </div>
        </div>
      ) : !isEmpty(data?.payObj) && Object.keys?.(data?.payObj)?.length > 0 ? (
        <PaymentAggregator {...data?.payObj} />
      ) : (
        <>
          {data?.initialStatus ? (
            <div
              style={{
                display: 'flex',
                height: '100vh',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <PaymentStatus
                primaryColor={primaryColor}
                status={'fail'}
                message={
                  checkNull(data?.failMessage)
                    ? 'Authentication Failed'
                    : data?.failMessage
                }
              />
            </div>
          ) : isEmpty(data?.payObj) && !data?.initialStatus ? (
            <div
              style={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                No Data
              </Typography>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}

export default HomePage
