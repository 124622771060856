export const currency_symbol = {
  IN: '₹',
  JPN: '¥',
  KOR: '₩',
  BTN: 'Nu.',
  BGD: '৳',
  AUS: '$',
  MYS: 'RM',
  IDN: 'Rp',
  VNM: '₫',
  COL: '$',
  BRA: 'R$',
  CHL: '$',
  BOB: '$b',
  MEX: '$',
  CAN: '$',
  DMA: 'EC$',
  SGP: '$',
  DEU: '€',
  US: '$',
  MNG: '₮',
  BHS: '$',
  DZA: 'د.ج',
  AND: '€',
  AGO: 'Kz',
  ARG: '$',
  ARM: '֏',
  AUT: '€',
  AZE: '₼',
  BHR: 'BD',
  BRB: '$',
  BLR: 'Br',
  BEL: '€',
  BMU: '$',
  BWA: 'P',
  BVT: 'NOK',
  BRN: '$',
  BGR: 'лв',
  BFA: 'XOF',
  KHM: '៛',
  CYM: 'CI$',
  CHN: '¥',
  HRV: 'kn',
  DNK: 'kr',
  EGY: 'E£',
  FIN: '€',
  FRA: '€',
  GUF: '€',
  GAB: 'F.CFA',
  GEO: '₾',
  GRC: '€',
  GIN: 'FG',
  GUY: 'G$',
  HKG: 'HK$',
  HUN: 'Ft',
  ISL: 'kr',
  IRN: '﷼',
  IRQ: 'ع.د',
  IRL: '€',
  ISR: '₪',
  ITA: '€',
  JAM: 'J$',
  JEY: '£',
  JOR: 'JD',
  KAZ: '₸',
  KEN: 'KSh',
  KGZ: 'KGS',
  LBN: '.ل.ل',
  LBR: 'LRD',
  LBY: 'ل.د',
  LIE: 'Fr',
  LUX: '€',
  MKD: 'ден',
  MDG: 'Ar',
  MDV: ' .ރ',
  FSM: '$',
  MAR: 'د.م.',
  MOZ: 'MT',
  MMR: 'K',
  NAM: 'N$',
  NPL: 'रु',
  NLD: '€',
  NZL: 'NZ$',
  NGA: '₦',
  PRK: '₩',
  NOR: 'kr',
  OMN: 'ر.ع.',
  PAK: '₨',
  PHL: '₱',
  POL: 'zł',
  PRT: '€',
  QAT: 'ر.ق',
  ROU: 'lei',
  RUS: '₽',
  KNA: 'EC$',
  YEM: '﷼',
  ZMB: 'ZK',
  ZWE: 'Z$',
  GBR: '£',
  LKA: '₨',
  AFG: '؋',
  ALB: 'Lek',
  ATG: '$',
  BLZ: 'BZ$',
  BEN: 'CFA',
  BIH: 'KM',
  BDI: 'FBu',
  CPV: 'Esc',
  CMR: 'FCF',
  CAF: 'FCF',
  TCD: 'FCFA',
  COM: 'CF',
  COG: 'FCF',
  COK: '$',
  CRI: '₡',
  CUB: '₱',
  CYP: '€',
  FJI: '$',
  CZE: 'Kč',
  DJI: 'Fdj',
  ECU: '$',
  SLV: '$',
  GNQ: 'FCF',
  ERI: 'Nfk',
  EST: 'Kr',
  SWZ: 'E',
  ETH: 'ብር',
  PER: 'S/.',
  PRY: 'Gs',
  PNG: 'K',
  PAN: 'B/.',
  PLW: '$',
  NRU: '$',
  MNE: '€',
  MCO: '€',
  MUS: 'Rs',
  MRT: 'UM',
  MHL: '$',
  MLT: '€',
  MLI: 'CFA',
  MWI: 'MK',
  LTU: 'Lt',
  LSO: 'L',
  LVA: 'Ls',
  KWT: 'د.ك',
  KIR: '$',
  HND: 'L',
  HTI: 'G',
  GTM: 'Q',
  GRD: '$',
  GHA: '¢',
  GMB: 'D',
  LCA: '$',
  WSM: 'WS$',
  SMR: '€',
  STP: 'Db',
  SAU: '﷼',
  SEN: 'XOF',
  SRB: 'Дин.',
  SYC: 'Rs',
  SLE: 'Le',
  SVK: 'Sk',
  SVN: '€',
  SLB: '$',
  SOM: 'S',
  ZAF: 'R',
  SSD: 'SS£',
  VEN: 'Bs',
  VUT: 'Vt',
  UZB: 'лв',
  URY: '$U',
  TZA: 'TZS',
  ARE: 'د.إ',
  UKR: '₴',
  UGA: 'Ush',
  TUV: '$',
  TKM: 'm',
  TUR: 'YTL',
  TUN: 'د.ت',
  TTO: 'TT$',
  TON: 'T$',
  TGO: 'Fr',
  TLS: '$',
  THA: '฿',
  TJK: 'SM',
  SYR: '£',
  CHE: 'CHF',
  SWE: 'Kr',
  SUR: '$',
  ESP: '€',
}
