import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalBtnStyle: {},
  modalTitle: {
    width: '90%',
    fontSize: '20px !important',
    marginBottom: '10px !important',
    lineHeight: '30px !important',
    color: '#000',
    '@media (max-width:1024px)': {
      fontSize: '20px !important',
    },
    '@media (max-width:768px)': {
      lineHeight: '30px !important',
    },
  },
  pricingBreakdown: {
    margin: '10px 0px 30px 0px',
    textTransform: 'capitalize',
  },
  pricingBreakdown_final: {
    color: '#0068EF !important',
  },
  pricingBreakdown_amount: {
    fontWeight: 'bold',
  },
  achDescription: {
    fontSize: '15px !important',
    marginBottom: '10px !important',
    lineHeight: '20px !important',
    '@media (max-width:768px)': {
      lineHeight: '20px !important',
    },
  },
}))

export default useStyles
