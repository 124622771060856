import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { isArray, isEmpty, isUndefined } from 'lodash'
import { KeyboardArrowRight } from '@mui/icons-material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CustomCard from '../CustomCard'
import { browserName } from 'react-device-detect'
import { getApiDataProgressPayment } from '../APIHelper'
import CryptoJS from 'crypto-js'
import { useTheme } from '@emotion/react'
import useStyles from './style'
import ConfirmDialog from '../ConfirmDialog'
import { Strings } from '../../Strings'

/**
 * CardDetails React Component.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {Function} props.onPaymentDone - Callback function invoked when payment is completed.
 * @param {Object} props.paymentData - Data related to the payment.
 * @param {Object} props.paymentMethod - Payment method information.
 * @param {string} props.liveUrl - URL for live environment.
 * @param {Function} props.setPaySuccess - Function to set the payment success state.
 * @param {number} props.activeIndex - Active index indicating the current step or section.
 * @param {Object} props.chargeData - Data related to the charge or payment process.
 * @param {boolean} props.noCharge - Flag indicating whether there is no charge.
 * @param {Function} props.changeBtnText - Function to change the text of a button.
 * @param {string} props.linearColorsName - Name of linear gradient colors for buttons.
 * @param {boolean} props.isGradientButton - Flag indicating whether buttons have a gradient.
 * @param {string} props.paymentSelectedType - Type of selected payment method.
 * @returns {JSX.Element} CardDetails React component.
 */
const CardDetails = (props) => {
  const {
    onPaymentDone,
    paymentData,
    paymentMethod,
    liveUrl,
    setPaySuccess,
    activeIndex,
    chargeData,
    noCharge,
    changeBtnText,
    linearColorsName,
    isGradientButton,
    paymentSelectedType,
    primaryColor,
    currency_symbol,
  } = props

  const Ref = useRef(null)
  const styles = useStyles()
  const [contentLoade, setContentLoader] = useState(false)
  const [isSelected, setIsSelected] = useState('')

  const [paymentType, setPaymentType] = useState([])
  const [paymentSearchType, setPaymentSearchType] = useState([])
  const [tabSelected, setTabSelected] = useState({})

  const [isBankSelect, setIsBankSelect] = useState({})

  const currentDomain = window.location
  const [clientSecret, setClientSecret] = useState('')

  const [search, setSearch] = useState('')
  const [confirmDialog, setConfirmDialog] = useState(false)

  const [isHovered, setIsHovered] = useState({})
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const lg = useMediaQuery(theme.breakpoints.down('lg'))
  const isRecurring =
    paymentData?.payThroughRecurrence ||
    paymentData?.payThroughEMI ||
    paymentData?.updateSubscription

  const paymentGatwayFee = (
    tabSelected?.charge_object?.charges_obj?.final_amount -
      chargeData?.withChargeAmount || 0
  )?.toFixed(2)

  const finalAmount = noCharge
    ? paymentData?.amount || 0
    : // : chargeData?.isPaymentGateWay
      // ? cardBrandSelect?.charge_object?.charges_obj?.final_amount ||0
      chargeData?.withChargeAmount

  const handleMouseEnter = (index) => {
    setIsHovered({ index })
  }

  const handleMouseLeave = () => {
    setIsHovered({})
  }

  const findIn = paymentMethod?.findIndex(
    (v) =>
      v?.type === paymentSelectedType &&
      v['payment_method.payment_method'] !== 'Google Pay' &&
      v['payment_method.payment_method'] !== 'Apple Pay'
  )

  const filterMethod = paymentMethod?.filter(
    (v) =>
      v?.type === paymentSelectedType &&
      v['payment_method.payment_method'] !== 'Google Pay' &&
      v['payment_method.payment_method'] !== 'Apple Pay'
  )
  const isMultiple = isArray(filterMethod) && filterMethod?.length > 1

  useEffect(() => {
    if (findIn !== -1) {
      if (
        paymentMethod[findIn]['payment_method.payment_method'] == 'Apple Pay'
      ) {
        setOpen(true)
      } else if (
        paymentMethod[findIn]['payment_method.payment_method'] == 'UPI'
      ) {
        setTabSelected(paymentMethod[findIn])
      } else if (
        paymentMethod[findIn]['payment_method.payment_method'] == 'ACH' &&
        isEmpty(paymentMethod[findIn]?.ach_fields)
      ) {
        setTabSelected(paymentMethod[findIn])
      } else {
        getPaymentOption(paymentMethod[findIn]?.payment_method_id, findIn)
      }

      setIsSelected(findIn)
    }
  }, [findIn])

  useEffect(() => {
    setIsBankSelect({})
  }, [isSelected])

  function filterVal(val) {
    if (isEmpty(val)) {
      setPaymentSearchType(paymentType)
    } else {
      const newData = paymentType?.filter((item) => {
        return (
          item['payment_sub_method.type']
            ?.toLowerCase()
            .indexOf(val?.toLowerCase()) > -1
        )
      })
      setPaymentSearchType(newData)
    }
    setSearch(val)
  }

  /**
   * Save order and initiate payment process.
   *
   * @async
   * @function
   * @param {Object} subData - Data related to the payment method.
   * @param {string} type - Type of payment.
   * @throws {Error} Throws an error if the operation fails.
   * @returns {Promise<void>} A promise that resolves when the order is saved and payment process is initiated.
   */
  async function SaveOrder(subData, type) {
    setPaySuccess('loading')
    try {
      const data = {
        name: paymentData?.name,
        amount: chargeData?.withChargeAmount,
        final_amount: subData?.charge_object?.charges_obj?.final_amount,
        app_token: paymentData?.app_token,
        country_id: subData?.country_id,
        currency: paymentData?.currency,
        mode: chargeData?.mode,
        payment_method_id: subData?.payment_method_id,
        payment_sub_method_id: subData?.payment_sub_method_id,
        transaction_code: paymentData?.transaction_code,
        gateway_code: subData?.charge_object?.gateway_code,
        gateway_id: subData?.gateway_id,
        email: paymentData?.email,
        payment_gateway_fee: noCharge
          ? 'inclusive'
          : chargeData?.isPaymentGateWay
          ? 'exclusive'
          : 'inclusive',
        base_amount: paymentData?.amount,
        charge_id: subData?.charge_object?.charges_obj?.id,
        platform: 'web',
        charges_json: JSON.stringify(chargeData?.mainChargeData),
        mobile: paymentData?.mobile,
        chargeAsFixedAmount: paymentData?.chargeAsFixedAmount,
      }
      const response = await getApiDataProgressPayment(
        `${liveUrl}save-order`,
        'POST',
        JSON.stringify(data),
        chargeData?.auth_token
      )
      if (response?.status == false) {
        setPaySuccess('fail', response?.message)
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else {
        setTimeout(() => {
          // PaycallBack(response?.code, "", type, subData);
          paymentApi(subData, response?.code)
        }, 1000)
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  async function PaycallBack(code, token, type, subData) {
    try {
      const data = {
        token,
      }

      const response = await getApiDataProgressPayment(
        `${liveUrl}pay-callback/${code}`,
        'POST',
        type === 'gPay' ? JSON.stringify(data) : {},
        chargeData?.auth_token
      )
      if (response?.status == false) {
        // Alert.alert(
        //   "Error",
        //   response?.message || "Please try again. Something got wrong."
        // );

        setPaySuccess('fail')
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else {
        setClientSecret(response?.data?.client_secret)
        // applePay(subData, response?.data?.client_secret, code);
        // handleApplePayClick(subData, response?.data?.client_secret);
      }
    } catch (error) {
      setPaySuccess('fail')
      setTimeout(() => {
        setPaySuccess(false)
      }, 5000)
      console.log('error:', error)
    }
  }

  /**
   * Fetch payment options based on the selected method and country.
   *
   * @param {string} method - The payment method to filter options (optional).
   * @param {number} index - The index of the payment option (not used).
   * @throws {Error} Throws an error if the operation fails.
   * @returns {Promise<void>} A promise that resolves with the fetched payment options.
   */
  const getPaymentOption = (method, index) => {
    setContentLoader(true)
    try {
      fetch(
        `${liveUrl}payment-options/${paymentData.country_code}?method=${
          method ? method : ''
        }&mode=${chargeData?.mode}&amount=${
          noCharge ? paymentData?.amount : chargeData?.withChargeAmount
        }&currency=${paymentData?.currency}&reccurance=${isRecurring}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${chargeData?.auth_token}`,
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.status) {
            setPaymentType(response?.data)
            setPaymentSearchType(response?.data)
            setTabSelected(response?.data[0])
            setContentLoader(false)
          } else {
            if (
              response?.statusCode === 401 &&
              response?.message ===
                'Unauthorized. Access token is missing or invalid.'
            ) {
            }
            setContentLoader(false)
          }
        })
        .catch((err) => {
          console.log(err.message)
          setContentLoader(false)
        })
    } catch (error) {
      setContentLoader(false)
      console.log('ERRRRR', error)
    }
  }

  /**
   * Process payment API with the provided data and order code.
   *
   * @param {Object} data - The payment data for the API request.
   * @param {string} code - The order code associated with the payment.
   * @throws {Error} Throws an error if the payment API request fails.
   * @returns {Promise<void>} A promise that resolves after processing the payment.
   */
  async function paymentApi(data, code) {
    let final_data = {
      amount: {
        amount: paymentData?.amount,
        final_amount: data?.charge_object?.charges_obj?.final_amount,
      },
    }

    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(final_data),
      '470cb677d807b1e0017c50b'
    ).toString()

    const Ddata = {
      data: ciphertext,
      order_code: code,
      is_new: 1,
      redirect_url: currentDomain?.origin,
    }

    try {
      const response = await getApiDataProgressPayment(
        `${liveUrl}custom-checkout`,
        'POST',
        JSON.stringify(Ddata),
        chargeData?.auth_token
      )
      if (isUndefined(response) || response?.status === false) {
        setPaySuccess('fail', response?.message)
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else if (
        (response?.status === 'processing' || response?.status === true) &&
        paymentData?.currency !== 'INR'
      ) {
        if (response?.data && response?.data.redirect_url) {
          setPaySuccess('loading', '', {
            redirect_url: response?.data.redirect_url,
            code: code,
          })
        }
      } else {
        if (!isEmpty(data)) {
          setPaySuccess('url', response?.data.redirect_url)
        } else {
          setPaySuccess('success')
          onPaymentDone()
        }
      }
    } catch (error) {
      setPaySuccess('fail')
      setTimeout(() => {
        setPaySuccess(false)
      }, 5000)
      console.log('error:', error)
    }
  }

  /**
   * Check the progress of a payment using the provided order code and transaction code.
   *
   * @param {string} code - The order code associated with the payment.
   * @throws {Error} Throws an error if there's an issue checking the payment progress.
   * @returns {Promise<void>} A promise that resolves after checking the payment progress.
   */

  /**
   * Render the first display of payment methods.
   *
   * @returns {React.ReactNode} React node representing the rendered payment methods.
   */
  function renderFirstDisplay() {
    return (
      isArray(paymentMethod) &&
      !isEmpty(paymentMethod) &&
      paymentMethod?.map((item, index) => {
        const isLowest =
          isArray(activeIndex) &&
          paymentMethod[activeIndex[0]]['payment_method.payment_method'] ==
            'Google Pay'
            ? paymentMethod[activeIndex[1]]['payment_method.payment_method'] ==
                'Apple Pay' && browserName === 'Safari'
              ? activeIndex[2]
              : activeIndex[1]
            : activeIndex[0]

        const isLast = filterMethod[filterMethod?.length - 1]
        return item['payment_method.payment_method'] === 'Google Pay' ||
          item['payment_method.payment_method'] === 'Apple Pay'
          ? null
          : item?.type === paymentSelectedType && (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRight:
                    isMultiple && isLast?.id !== item?.id
                      ? '1px solid #c9c9c9'
                      : 'unset',
                }}
              >
                <div
                  style={{
                    padding: 10,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid',
                    borderRadius: 6,
                    borderColor:
                      isSelected === index
                        ? primaryColor
                        : isLowest === index
                        ? '#3cd070'
                        : '#c9c9c9',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (item['payment_method.payment_method'] == 'Apple Pay') {
                      // SaveOrder(item, "aPay");
                      setOpen(true)
                    } else if (item['payment_method.payment_method'] == 'UPI') {
                      setTabSelected(item)
                      setIsSelected(index)
                    } else if (
                      item['payment_method.payment_method'] == 'ACH' &&
                      isEmpty(item?.ach_fields)
                    ) {
                      setTabSelected(item)
                      setIsSelected(index)
                    } else {
                      setIsSelected(index)
                      getPaymentOption(
                        paymentMethod[index]?.payment_method_id,
                        index
                      )
                    }
                  }}
                >
                  <img
                    style={{
                      objectFit: 'contain',
                      borderRadius: 4,
                      width: sm ? 35 : 40,
                      height: sm ? 35 : 40,
                    }}
                    src={item['payment_method.logo'] || '-'}
                    alt={'logo'}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <Typography
                      style={{ fontSize: sm ? 16 : 16, fontWeight: 'bold' }}
                    >
                      {item['payment_method.payment_method']}
                    </Typography>
                    {chargeData?.isPaymentGateWay && !noCharge && (
                      <Typography
                        style={{ fontSize: sm ? 14 : 16, color: '#8E8E8E' }}
                      >
                        {`Starts @${
                          Number(
                            item?.charge_object?.charges_obj?.transaction_per ||
                              0
                          ) +
                          Number(
                            item?.charge_object?.charges_obj
                              ?.currency_conversion_percentage || 0
                          ) +
                          Number(
                            item?.charge_object?.charges_obj
                              ?.international_charge_percentage || 0
                          )
                        }% fee`}{' '}
                        {item?.charge_object?.charges_obj?.fixed_fee_amount
                          ? `+ ${currency_symbol[paymentData?.country_code]}${
                              item?.charge_object?.charges_obj
                                ?.fixed_fee_amount || ''
                            }`
                          : ''}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            )
      })
    )
  }

  const isForUpdateSub = paymentData?.updateSubscription

  return (
    <Grid container style={{ flexGrow: 1 }}>
      <Grid
        item
        xs={12}
        lg={isForUpdateSub ? 12 : 8.7}
        style={{
          margin: lg ? '10px 0px' : '0px 10px',
        }}
      >
        <Paper
          elevation={2}
          style={{
            borderRadius: 4,
            borderWidth: 1,
            height: 'auto',
            width: '100%',
            padding: 10,
            boxSizing: 'border-box',
          }}
        >
          {isMultiple && (
            <div
              style={{
                padding: 10,
                borderBottom: '1px solid #c6c6c6',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 20,
              }}
            >
              <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                {paymentSelectedType === 'card'
                  ? 'Select any one card payment option'
                  : paymentSelectedType === 'bank'
                  ? 'Select any one bank option for payment'
                  : 'Select any one digital wallet option'}
              </Typography>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginBottom: 20,
            }}
          >
            {renderFirstDisplay()}
          </div>

          {contentLoade ? (
            <div
              style={{
                height: 300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={30} style={{ color: primaryColor }} />
            </div>
          ) : isSelected &&
            (paymentMethod[isSelected]['payment_method.payment_method'] ===
              'UPI' ||
              (paymentMethod[isSelected]['payment_method.payment_method'] ==
                'ACH' &&
                isEmpty(paymentMethod[isSelected]?.ach_fields)) ||
              !isEmpty(isBankSelect)) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!isEmpty(isBankSelect) && (
                <div style={{ marginBottom: 10 }}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {isBankSelect['payment_sub_method.type']}
                  </Typography>
                </div>
              )}
              <Button
                sx={{
                  opacity: 1,
                  background: isGradientButton
                    ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                    : primaryColor,
                  ':hover': {
                    background: isGradientButton
                      ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                      : primaryColor,
                  },

                  fontSize: 16,
                }}
                className={styles.payButton}
                onClick={async () => {
                  setConfirmDialog(true)
                }}
              >
                {changeBtnText} {currency_symbol[paymentData?.country_code]}
                {Number(finalAmount)?.toFixed(2)}
              </Button>
              {!isEmpty(isBankSelect) && (
                <div style={{ marginTop: 6 }}>
                  <Typography
                    style={{
                      fontSize: 14,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: primaryColor,
                    }}
                    onClick={() => {
                      setIsBankSelect({})
                    }}
                  >
                    {paymentMethod[isSelected][
                      'payment_method.payment_method'
                    ] === 'Net Banking'
                      ? 'Select another bank'
                      : 'Select another wallet'}
                  </Typography>
                </div>
              )}
            </div>
          ) : isSelected &&
            (paymentMethod[isSelected]['payment_method.payment_method'] ===
              'Net Banking' ||
              paymentMethod[isSelected]['payment_method.payment_method'] ===
                'Wallet') ? (
            <>
              <TextField
                fullWidth
                size='medium'
                variant='outlined'
                sx={{
                  '.css-1bfgl9n-MuiInputBase-input-MuiOutlinedInput-input': {
                    fontSize: '16px',
                  },
                }}
                style={{ marginBottom: 10 }}
                className={styles.input}
                placeholder={'Search Bank Name'}
                label={'Search'}
                value={search}
                onChange={(e) => {
                  filterVal(e.target.value)
                }}
              />
              <div style={{ maxHeight: 500, overflow: 'auto' }}>
                {isArray(paymentSearchType) && !isEmpty(paymentSearchType) ? (
                  paymentSearchType?.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '10px 10px',
                          cursor: 'pointer',
                          borderRadius: '5px',
                          backgroundColor:
                            !isEmpty(isHovered) && isHovered.index === index
                              ? '#A0E9FF'
                              : 'white',
                        }}
                        onMouseEnter={() => {
                          handleMouseEnter(index)
                        }}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => {
                          // setConfirmDialog(true);
                          setIsBankSelect(item)
                          setTabSelected(item)
                        }}
                      >
                        <div
                          style={{
                            borderWidth: 1,
                            borderColor: '#00000020',
                            height: 30,
                            width: 40,
                            overflow: 'hidden',
                          }}
                        >
                          <img
                            src={item['payment_sub_method.logo']}
                            style={{
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                            }}
                            alt='img'
                          />
                        </div>

                        <Typography
                          style={{
                            flex: 1,
                            fontSize: 14,
                            marginLeft: 8,
                          }}
                          numberOfLines={2}
                        >
                          {item['payment_sub_method.type']}
                        </Typography>

                        <KeyboardArrowRight
                          style={{
                            color:
                              !isEmpty(isHovered) && isHovered.index === index
                                ? '#000'
                                : '#00000040',
                          }}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      height: 500,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                      No Data
                    </Typography>
                  </div>
                )}
              </div>
            </>
          ) : (
            <CustomCard
              {...props}
              paymentType={paymentType}
              paymentData={paymentData}
              currency_symbol={currency_symbol}
              cardBrandSelect={tabSelected}
              chargeData={chargeData}
              noCharge={noCharge}
              primaryColor={primaryColor}
              setPaySuccess={setPaySuccess}
              onPaymentDone={onPaymentDone}
              changeBtnText={changeBtnText}
              type={
                isSelected &&
                paymentMethod[isSelected]['payment_method.payment_method']
              }
              setTabValue={(value) => {
                setTabSelected(value)
              }}
              liveUrl={liveUrl}
            />
          )}
        </Paper>
      </Grid>
      {!isForUpdateSub && (
        <Grid item xs={12} lg={3} style={{}}>
          <Paper
            elevation={2}
            style={{
              borderRadius: 4,
              borderWidth: 1,
              height: 'auto',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <div style={{ padding: 10, borderBottom: '1px solid #c6c6c6' }}>
              <Typography
                textAlign={'center'}
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                Payment Summary
              </Typography>
            </div>
            <div className={styles.pricingBreakdown}>
              <Grid
                container
                justifyContent='space-between'
                style={{ padding: '0px 10px' }}
              >
                <Typography style={{ fontSize: 14 }}>Amount</Typography>
                <Typography style={{ fontSize: 14 }}>
                  {currency_symbol[paymentData?.country_code]}
                  {chargeData?.withoutChargeAmount.toFixed(2)}
                </Typography>
              </Grid>

              {isArray(chargeData?.mainChargeData) &&
                !isEmpty(chargeData?.mainChargeData) &&
                chargeData?.mainChargeData?.map((item, index) => {
                  const amountToAdd = paymentData?.chargeAsFixedAmount
                    ? item?.value
                    : (item?.value * paymentData?.amount) / 100
                  return (
                    <>
                      {item?.slug !== 'payment_gateway_fee' && (
                        <Grid
                          container
                          justifyContent='space-between'
                          style={{ padding: '0px 10px' }}
                        >
                          <Typography style={{ fontSize: 14 }}>
                            {item?.name}
                          </Typography>
                          <Typography style={{ fontSize: 14 }}>
                            {currency_symbol[paymentData?.country_code]}
                            {item?.slug === 'payment_gateway_fee'
                              ? Number(paymentGatwayFee)?.toFixed(2)
                              : amountToAdd?.toFixed(2)}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )
                })}
              <Grid
                container
                justifyContent='space-between'
                className={styles.pricingBreakdown_final}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Payable amount
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  {currency_symbol[paymentData?.country_code]}
                  {Number(finalAmount)?.toFixed(2)}
                </Typography>
              </Grid>
            </div>
          </Paper>
          <Grid display='flex' justifyContent='center' alignItems='center'>
            <Typography fontSize={14} mt={1} color={'#9D9D9D'}>
              + Including Applicable Charges
            </Typography>
          </Grid>
          <Grid
            display='flex'
            justifyContent='center'
            alignItems='center'
            mb={3}
            style={{ marginTop: 10 }}
          >
            <Typography fontSize={12}>Powered by&nbsp;</Typography>
            <Typography
              fontWeight={'bold'}
              fontSize={14}
              className={styles.gradientText}
            >
              AautiPay
            </Typography>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity='error'
          sx={{ width: '100%' }}
        >
          Apple Pay is in progress!
        </Alert>
      </Snackbar>
      <ConfirmDialog
        title={`Are you sure you want to make payment with ${
          !isEmpty(tabSelected) && tabSelected?.payment_method_id === 8
            ? 'ACH'
            : tabSelected?.payment_method_id === 3
            ? 'UPI'
            : tabSelected?.payment_method_id === 4
            ? 'Wallet'
            : 'ACH'
        }?`}
        visible={confirmDialog}
        changeBtnText={changeBtnText}
        chargeData={chargeData}
        primaryColor={primaryColor}
        paymentData={paymentData}
        currency_symbol={currency_symbol}
        finalAmount={finalAmount}
        paymentGatwayFee={paymentGatwayFee}
        linearColorsName={linearColorsName}
        isGradientButton={isGradientButton}
        noCharge={noCharge}
        tabSelected={tabSelected}
        handleModal={(bool) => {
          if (bool) {
            SaveOrder(tabSelected, 'saveO')
          } else {
            setConfirmDialog(false)
          }
        }}
      />
    </Grid>
  )
}

export default CardDetails
